import {
  useState
} from 'react'
import './index.scss'
import productItems from '../../products/productsItems'
import ProductCount from '../product-count'
import OrderButton from '../order-button'
import Description from '../description'

const ProductTwo = ({ productId }) => {
  const productItem = productItems.filter(i => i?.id === productId)[0]
  const [count, setCount] = useState(1)

  return (
    <div className="product-info">
      <h1 className="title">
        {productItem.title}
      </h1>
      <ProductCount
        price={productItem?.price}
        count={count}
        setCount={setCount}
      />
      <OrderButton order={{ count, price: productItem?.price, productItem }} btnText={'Передзамовлення'} />
      <Description>
        <a
          href="https://docs.google.com/document/d/12Z7F9SMmRfgv9Tbxce0ttFs7Y7QhT3ZO/edit?usp=sharing&ouid=111034180304603509934&rtpof=true&sd=true"
          target="_blank"
        >
          Технічні характеристики тепловізійного комплекту Seek UAV 256 Duo
        </a>
        <p>
          Тепловізійний комплект для FPV-дронів Seek UAV 256 Duo містить у собі тепловізор Seek UAV 256 та сутінкову камеру Caddx Ratel Pro з платою перемикання між ними. Він дає змогу застосувати дрон у будь-який час доби, має малі габарити та одразу готовий до установки на борт БПЛА.
        </p>
        <p>
          Вартість тепловізору значно знижується при купівлі від <b>3</b> одиниць, та становить до <b>7900 грн</b> за одиницю при масовому замовленні
        </p>
        <p>
          Всі компоненти, крім матриці та лінзи, тепловізору розроблені та виготовляються в Україні з найкращих комплектуючих. Крім того ми надаємо офіційну гарантію на всі тепловізори та забезпечуємо їх пожиттєве сервісне обслуговування.
        </p>
      </Description>
    </div>
  )
}

export default ProductTwo